import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { useMount, useList } from 'react-use'
import { forEach } from 'lodash'

import { Layout, ProductForm, Grid, GridItem } from '../components'
import { container, padding, bgImage, bgIcon } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import { client } from '../utils/shopify'

const ProductSingle = (props) => {
    const [data, setData] = useState(false)
    const [activeVariant, setActiveVariant] = useState(null)
    const [relatedProductsData, { push: addRelatedProduct }] = useList([])
    const { relatedProducts } = props?.pageContext

    useMount(() => {
        // fetch this products data
        client.product
            .fetchByHandle(props.pageContext?.handle)
            .then((product) => {
                setData({
                    ...product,
                })
            })

        // fetch related products data
        if (relatedProducts) {
            forEach(relatedProducts, (item, key) => {
                client.product.fetchByHandle(item.handle).then(product => {
                    addRelatedProduct({ ...product })
                })
            })
        }
    })

    const renderImages = () => {
        if (!data.images) return
        const items = data.images.map((item, i) => {
            return (
                <Image
                    key={item.src}
                    src={item.src}
                >
                    {(src, loading) => {
                        return (
                            <LoadedImage
                                src={src} 
                                style={{opacity: loading ? 0 : 1}}  
                            />
                        )
                    }}
                </Image> 
            )
        })
        return (
            <ScrollWrapper>
                <Images>
                    {items}
                </Images>
            </ScrollWrapper>
        )
    }

    const renderText = () => {
        return (
            <Text>
                <Heading>
                    {data?.title}
                </Heading>

                {data && (
                    <ProductForm
                        productData={data}
                        selectedOptions={null}
                    />
                )}
                
                <Description
                    dangerouslySetInnerHTML={{
                        __html: data?.descriptionHtml,
                    }}
                />
            </Text>
        )
    }

    const renderRelated = () => {
        if (!relatedProductsData || !data) return

        const items = relatedProductsData.map((item, i) => {
            return (
                <GridItem
                    key={i}
                    item={item}
                />
            )
        })

        return (
            <Related>
                <Heading>Related</Heading>
                <Grid items={items} />
            </Related>
        )
    }

    return (
        <Layout
            meta={{
                title: data.title && `${data.title} – Bedroom Suck Records`
            }}
        >
            <Wrapper>
                <Container>
                    {renderImages()}
                    {renderText()}
                    {renderRelated()}
                </Container>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const LoadedImage = styled.img``

const BGIcon = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
`

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 71px;
    padding-bottom: 90px;

    ${media.phone`
        padding-top: 31px;
        padding-bottom: 40px;
    `}
`

const Container = styled.div`
    ${container}
    ${padding}
    display: flex;
    flex-flow: row wrap;

    ${media.phone`
        flex-direction: column;
        flex-wrap: nowrap;
    `}
`

// Images

const ScrollWrapper = styled.div`
    width: 50%;
    padding-right: 10px;

    ${media.phone`
        width: 100vw;
        max-width: 100vw;
        overflow-x: scroll;
        padding-right: 0;
    `}
`

const Images = styled.div`
    width: 100%;

    ${media.phone`
        display: flex;
        
        > *:last-child {
            padding-right: 13px;
        }
    `}

    > *:not(:last-child) {
        margin-bottom: 30px;

        ${media.phone`
            margin-bottom: 0;
            margin-right: 13px;
        `}
    }

    ${LoadedImage} {
        width: 100%;
        height: auto;

        ${media.phone`
            max-width: 90vw;
            max-height: 300px;
            height: 100%;
        `}
    }
`

// Text

const Text = styled.div`
    width: 50%;
    max-width: 335px;
    padding-left: 10px;

    ${media.phone`
        width: 100%;
        margin-top: 17px;
        max-width: initial;
        padding-left: 0;
    `}

    ${Heading} {
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 115.5%;
        text-transform: capitalize;

        ${media.phone`
            font-size: 18px;
            line-height: 115.5%;
        `}
    }

    ${Description} {
        margin-top: 29px;

        &, * {
            font-family: Courier;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            ${media.phone`
                font-size: 16px;
                line-height: 24px;
            `}
        }
        
        /* Formatting fixes */

        .Apple-converted-space {
            display: none;
        }
    }
`

// Related

const Related = styled.div`
    width: 100%;
    margin-top: 71px;

    ${media.phone`
        margin-top: 54px;
    `}

    > ${Heading} {
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 97.7%;
        color: black;
    }

    .grid-items {
        margin-bottom: 0;
    }

    /* only show the first two */
    ${media.phone`
        .grid-item:nth-child(n+3) {
            display: none;
        }
    `}
`

export default ProductSingle
